<template>
  <div class="new-total">
    <div class="content-bar">
      <div class="title">{{ title }}</div>
      <div class="info-bar">
        <div class="source">文章出处：{{ source }}</div>
        <div class="editor">编辑：{{ source }}</div>
      </div>
      <div class="line"></div>
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { getNewsDetail } from "@/api/index";
export default {
  data() {
    return {
      id: 0,
      title: "",
      source: "",
      editor: "",
      content: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getNewsDetail();
  },
  methods: {
    getNewsDetail() {
      getNewsDetail(this.id).then((res) => {
        this.title = res.object.newsTitle;
        this.source = res.object.source;
        this.editor = res.object.editor;
        this.content = res.object.newsContent;
      });
    },
  },
};
</script>

<style lang='less' scoped>
.new-total {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  div.content-bar {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 50px;
    div.title {
      font-size: 30px;
      font-weight: 400;
      line-height: 30px;
      color: #333333;
      opacity: 1;
      margin-top: 30px;
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: #cfcfcf;
      opacity: 1;
      margin-top: 30px;
    }

    div.info-bar {
      display: flex;
      margin-top: 24px;
      div.source {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: #999999;
        opacity: 1;
      }

      div.editor {
        margin-left: 100px;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: #999999;
        opacity: 1;
      }
    }

    div.content {
      width: 100%;
      margin-top: 30px;
    }
  }
}
</style>